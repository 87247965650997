<template>
    <div >
        <v-snackbar app small dense bottom centered tile elevation="24" :color="color" v-model="show_snackbar" :timeout="null" vertical max-width="400" min-width="400" style="position:fixed; width:400px; margin:0 auto;">
            <v-progress-linear dark indeterminate v-if="loading"></v-progress-linear>
            <v-btn x-small fab top right dark absolute style="border-radius:50%; top: -28px; right:28px;"  @click="show_snackbar = false">
                                <v-icon small>fas fa-times</v-icon>
                            </v-btn>
            <div v-for="(j, k) in jobs_q" :key="j.ID" class="downlod-block">
                <v-row class="px-3">       
                <v-icon v-if="j.Status == 'Error'" color="red">fa-regular fa-face-frown</v-icon>
                <v-progress-circular dark indeterminate v-else-if="j.Status == 'Running'" size="20" class="mr-1"></v-progress-circular>
                <v-icon small dark v-else-if="j.Status == 'Done'" left>fas fa-check</v-icon>
                <v-icon small dark v-else-if="j.Status == 'In Queue'" left>fas fa-stopwatch</v-icon>
                <span class="font-weight-bold">{{ j.JobName }}</span>
                </v-row>
                <v-row class="pa-2">
                    <v-btn text to="/user/my_exports">
                    All Exports
                    </v-btn>
                    <v-spacer></v-spacer>
                     <v-btn  v-if="j.Status == 'Done'"  color="#1a4686" dark @click="downloadExportFile(j)" class="mx-3">
                                <v-icon left dark >
                                    fa-solid fa-download
                                    </v-icon>
                                    <v-divider vertical class="ml-1 mr-2"></v-divider>
                                    Download
                                </v-btn>
                                <v-btn  v-if="j.Status == 'Error'"  color="#1a4686" @click="downloadErrorFile(j)" class="ml-5">
                                    <v-icon left dark >
                                        fa-solid fa-download
                                        </v-icon>
                                        <v-divider vertical class="ml-1 mr-2"></v-divider>
                                        Download
                                    </v-btn>
                </v-row>
            </div>   
        </v-snackbar>
    </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    name: "QSnackbarJobs",
    props: [], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            show_snackbar: false,
            text: null,
            color: 'info',
            details: null,
            show_details: false,
            jobs_q: [],
            loading: false,
            interval_id: null
        }
    },
    mounted: function() {
        var g = this;
        //expects a object like this 
        //{
        //  method : "GET",
        //  path : "/api/private/agents,
        //  params : {},
        //  JobName : "Agent Export"
        //}
        EventBus.$on("dispatchJob", function(job) {
            //first dispatch the job to the server
            g.show_snackbar = true;
            g.loading = true;
            QuilityAPI.dispatchExportJob(job).then(function(json) {
                g.loading = false;
                if (json.data) {
                    g.jobs_q.push(json.data);
                } else {
                    g.showError("That's odd... the job wasn't returned.")
                }
                g.color = 'info';
            }).catch(function(error) {
                g.loading = false;
            })
        });
    },
    computed: {

    },
    methods: {
        "ping": function() {
            var g = this
            if (this.show_snackbar) {
                this.jobs_q.forEach(function(j) {
                    if (j.Status != "Done" && j.Status != "Error") {
                        QuilityAPI.pingJobStatus(j.ID).then(function(json) {
                            g.updateJobStatus(json.data);
                        })
                    }
                })
            }
        },
        "updateJobStatus": function(new_j) {
            this.jobs_q.forEach(function(j) {
                if (j.ID == new_j.ID) {
                    j.Status = new_j.Status;
                }
            })
        },
        downloadExportFile: function(j) {
            this.loading = true
            var g = this;
            QuilityAPI.downloadExportJob(j.ID)
                .then(function(resp) {
                    g.loading = false
                    var fileURL = window.URL.createObjectURL(new Blob([resp]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
					const fileExtension = resp.type === 'application/zip' ? '.zip' : '.csv'
                    fileLink.setAttribute('download', 'Export_' + j.JobName + '_' + j.ID + fileExtension)
                    fileLink.setAttribute('target', '_new');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(function(err) {
                    g.loading = false
                    if (typeof err == 'undefined') {
                        return
                    }
                    if (err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    //g.showError(err.status)
                });
        },
        downloadErrorFile: function(j) {
            this.loading = true
            var g = this;
            QuilityAPI.downloadBulkJobErrorLogFile(j.ID)
                .then(function(resp) {
                    g.loading = false
                    var fileURL = window.URL.createObjectURL(new Blob([resp]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'Error_' + j.JobName + '_' + j.ID + '.txt');
                    fileLink.setAttribute('target', '_new');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(function(err) {
                    g.loading = false
                    if (typeof err == 'undefined') {
                        return
                    }
                    if (err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    //g.showError(err.status)
                });
        },
    },
    watch: {
        'show_snackbar': function(new_v) {
            this.show_details = false;
            if (new_v) {
                this.interval_id = setInterval(this.ping, 10000)
            } else {
                this.jobs_q = []
                clearInterval(this.interval_id);
            }
        }
    },
}
</script>
<style scoped>
.v-snack--bottom {
    bottom: 50px;
}
.downlod-block {
    border-bottom: 1px #ccc solid;
    padding-bottom:15px;
    margin-bottom:15px;
}

.downlod-block:first-child, .downlod-block:last-child {
    border-bottom: none;
    padding-bottom:5px;
    margin-bottom:5px;
}

</style>