import api from './QuilityAPI'

//this sets the available flags for a notice.
export interface NoticesFlags {
  snoozed:   boolean;
  archived:  boolean;
  favorited: boolean;
  acknowledged: boolean;
}

export interface NoticesGetParams extends NoticesFlags {
	search: string;
	author: string;
	page: string;
}

//this is basically the model definition for the popup (content part) of the notice.
export interface NoticePopup {
  id:                  number;
  created_at:          string;
  updated_at:          string;
  title:               string;
  content:             string;
  link:                string | null;
  link_text:           string | null;
  AgentCode:           string;
  audience:            string;
  selected_agents:     string;
  startDate:           string;
  endDate:             string;
  active:              number;
  send_sms:            number;
  filters:             string;
  add_acknowledge_btn: number;
}

//this is the ackjnowledgement model. Includes a popup from above.
export interface Notice extends NoticesFlags {
  id:                   number;
  created_at:           string;
  updated_at:           string;
  acknowledged:         boolean;
  acknowledgement_date: string | null;
  AgentCode:            string;
  popup_id:             number;
  popup:                NoticePopup;
}

// TODO: Standardize this and move to some base directory
export type Response<Data> = { data: Data, meta: Record<string, never> }

export type ResponseWithPaginationMeta = { total: number, per_page: number, current_page: number, last_page: number, from: number, to: number }
export type ResponseWithPagination<Data> = { data: Data, meta: ResponseWithPaginationMeta }

//this is a function for getting notices used for all types
//pass a params object - which should be a NoticesFlags object above, not all attributes are required.
export const getNotices = (
  params: Partial<NoticesFlags> = {},
  //pagination information
  meta?: ResponseWithPaginationMeta | null
) => {
  const searchParams = new URLSearchParams();

  //loops through the params and adds them to the URLSearchParams object.
  Object.keys(params).forEach((key) => {
    const param = params[key as keyof typeof params]!
    //check for null, because sometimes the value of the param is null and causes this to fail - freezing the app.
    if (param !== undefined && param != null) { searchParams.set(key, param.toString()) }
  })

  //if there is pagination needed, add to the search params.
  if (meta) {
    searchParams.set('page', meta.current_page.toString())
  } else {
	searchParams.set('page', '1')
  }

  searchParams.set('sortDesc', 'true')
  searchParams.set('sortOn', 'created_at')

  return api.getRequest('/api/private/popup-acknowledgements?' + searchParams.toString()) as Promise<ResponseWithPagination<Notice[]>>
}

//functions used for getting specific notices - uses the general funcation above.
export const getUnreadNotices = () => {
  return getNotices({ acknowledged: false, snoozed: false })
}

//functions used for getting specific notices - uses the general funcation above.
export const getSnoozedNotices = () => {
  return getNotices({ acknowledged: false, snoozed: true })
}

export const updateFlag = async (id: string | number, data: Partial<NoticesFlags>): Promise<Notice> => {
  if (data.acknowledged) {
	data.snoozed = false
  }

  const res = await api.putRequest(`/api/private/popup-acknowledgements/${id}`, data)
  return res.data
}

//make sure not to use trailing slashes on the urls... causes redirect issues with local development.
export const getUnreadNoticesCount = (): Promise<number> => {
  return api.getRequest('/api/private/unread-popup-acknowledgements').then((res: { unread_count: number }) => res.unread_count)
}
